import axios from '../plugins/axios' // Adjust the path as necessary

export default {
  // Organzation APIs 🏢

  getOrganizationList(page) {
    return axios.get(`/api/spritualnepal/organization/index?page=${page}`)
  },
  getOrganizationNameList() {
    return axios.get(`/api/spritualnepal/organization/fetch_name`)
  },

  registerOrganization(values) {
    return axios.post('/api/spritualnepal/organization/create_org', values, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  getIndividualOrganization(id) {
    return axios.get(`/api/spritualnepal/organization/show_org/${id}`)
  },

  updateOrganization(values) {
    return axios.post('/api/spritualnepal/organization/update_org', values, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  deleteOrganization(id) {
    return axios.delete(`/api/spritualnepal/organization/delete_org/${id}`)
  },

  // Branch APIs 🚽

  getBranchList(page) {
    return axios.get(`/api/spritualnepal/branch/index?page=${page}`)
  },

  registerBranch(values) {
    return axios.post('/api/spritualnepal/branch/create_branch', values, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  getIndividualBranch(id) {
    return axios.get(`/api/spritualnepal/branch/show_branch/${id}`)
  },

  updateBranch(values) {
    return axios.post('/api/spritualnepal/branch/update_branch', values, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  deleteBranch(id) {
    return axios.delete(`/api/spritualnepal/branch/delete_branch/${id}`)
  },

  // Dashboard APIs
  getDashboardData() {
    return axios.get('/api/spritualnepal/dashboard')
  },
  getAllBranchGeolocations() {
    return axios.get('/api/geo_location/index')
  },

  // Inventory APIs 🧻

  getInventoryList() {
    return axios.get('/api/spritualnepal/inventory/get_inventory')
  },
  createInventoryItem(values) {
    return axios.post('/api/spritualnepal/inventory/create_inventory', values, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  deleteInventoryItem(id) {
    return axios.delete(`/api/spritualnepal/inventory/delete_inventory/${id}`)
  },
  assignItemToBranch(values) {
    return axios.post(
      '/api/spritualnepal/inventory/assign_inventory_to_branches',
      values,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },
  fetchBranchInventoryFromDate(values) {
    return axios.post(
      '/api/spritualnepal/inventory/fetch_branch_inventory',
      values,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  },

  // Organization Verification APIs 📝

  getUnverifiedOrganizationList() {
    return axios.get(`/api/spritualnepal/verify/unapproved_org`)
  },
  getVerifiedOrganizatonList() {
    return axios.get(`/api/spritualnepal/verify/approved_org`)
  },
  verifyOrganization(id) {
    return axios.get(`/api/spritualnepal/verify/verify_org/${id}`)
  },
  unverifyOrganization(id) {
    return axios.get(`/api/spritualnepal/verify/unverify_org/${id}`)
  },

  // Review APIs 📝
  getAllReviewsList(page) {
    return axios.get(`/api/review/index?page=${page}`)
  },

  // Feedback APIs 📝
  getAllFeedbacksList(page) {
    return axios.get(`/api/spritualnepal/feedback/index?page=${page}`)
  },

  // Other libs

  ensureHttps(url) {
    if (!url.startsWith('https://')) {
      return 'https://' + url
    }
    return url
  },
  convertToNepaliNumerals(number) {
    const englishToNepali = {
      0: '०',
      1: '१',
      2: '२',
      3: '३',
      4: '४',
      5: '५',
      6: '६',
      7: '७',
      8: '८',
      9: '९',
    }

    return number
      .toString()
      .split('')
      .map((char) => englishToNepali[char] || char)
      .join('')
  },
}
